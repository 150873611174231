<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1 card-sipymex">
        <v-row>
          <v-col cols="12" md="9">
            <span class="white--text">Planes</span>
          </v-col>
          <v-col cols="12" md="3">
            <span class="m-20 float-right">
              <v-btn small color="white" :to="{ name: 'create-plan' }">
                <v-icon small dark> mdi-plus-circle </v-icon> Agregar plan
              </v-btn></span
            >
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="8"></v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <br />

        <v-data-table
          :headers="headers"
          :items="plans"
          :search="search"
          :footer-props="{
            itemsPerPageText: 'Filas por página',
            itemsPerPageAllText: 'todos',
          }"
          no-data-text="No hay datos disponibles"
          no-results-text="No hay datos disponibles"
        >
          <template v-slot:item.service="{ item }">
            <span>{{ getNameService(item.id_service) }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :to="'/actualizar-plan/' + item.id">
                  <v-icon v-bind="attrs" color="primary" v-on="on">
                    mdi-pencil-box-multiple
                  </v-icon>
                </v-btn>
              </template>
              <span>Modificar</span>
            </v-tooltip>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                  <v-card-text>
                    <div class="text-h7 pa-6">
                      ¿Estás seguro que deseas eliminar el plan?
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                    <v-btn
                      class="primary"
                      text
                      @click="
                        dialog.value = false;
                        deleteItem(item);
                      "
                      >Aceptar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    dialog: false,
    search: "",
    headers: [
      { text: "Nombre", value: "name" },
      { text: "Facturación", value: "billing" },
      { text: "Precio", value: "price" },
      { text: "Precio UF", value: "price_uf" },
      { text: "Descripción", value: "description" },
      { text: "Servicio", value: "service" },
      { text: "Acciones", value: "actions" },
    ],
    services: [],
    plans: [],
  }),

  computed: {},
  mounted() {
    this.action = "Eliminar";
    this.getServices();
    this.getPlans();
  },
  watch: {},

  methods: {
    getNameService(id_service) {
      //  this.getServices();
      let serviceName = this.services.find(
        (service) => service.id === id_service
      );

      return serviceName.name;
    },

    deleteItem(item) {
      console.log("eliminando");
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/destroy-plan", request)
        .then((response) => {
          this.displayNotification("success", "Éxito", "Plan eliminado correctamente");
          console.log(response.data);
          setTimeout(() => this.getServices(), 4000);
          this.getPlans();
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar el plan"
          );
          setTimeout(() => this.$router.push({ name: "plan" }), 4000);
        });
    },

    getServices() {
      axios
        .get("/services")
        .then((response) => {
          console.log("Servicios");
          console.log(response.data);
          this.services = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo obtener los servicios"
          );
        });
    },

    getPlans() {
      axios
        .get("/plans")
        .then((response) => {
          console.log(response.data);
          this.plans = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo obtener los planes"
          );
        });
    },
  },
};
</script>